var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tbData,"single-expand":_vm.singleExpand,"search":_vm.search,"loading":_vm.loadingTb,"loading-text":"Sedang Memuat Data...","expanded":_vm.expanded,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.noRow",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tbData.map(function(x) {return x.id; }).indexOf(item.id)+1)+" ")]}},{key:"item.birthDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.G_formatDate(item.birthDate,'d-M-Y'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-table",attrs:{"color":"#fafafa","fab":"","depressed":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"flat":"","dense":"","elevation":"1"}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":function($event){return _vm.detail(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Detail")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.pembukaanTabungan(item.id,item.cifCode)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pilih CIF")])],1)],1)],1)],1)],1)]}}])}),_c('G-dialogConfirm',{ref:"confirm"}),_c('G-dialogModal',{ref:"modal"},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-6"},[_c('h6',{staticClass:"text-warning mb-4"},[_vm._v("Data Sebelumnya")]),_c('G-detail',{attrs:{"title":"Nama Lengkap","value":"Jono"}}),_c('G-detail',{attrs:{"title":"Tempat Lahir","value":"Jakarta"}})],1),_c('div',{staticClass:"col-md-6"},[_c('h6',{staticClass:"text-success mb-4"},[_vm._v("Data Setelah")]),_c('G-detail',{attrs:{"title":"Nama Lengkap","value":"John Doe"}}),_c('G-detail',{attrs:{"title":"Tempat Lahir","value":"Bandung"}})],1)])]),_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","rounded":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Reject ")]),_c('v-btn',{attrs:{"color":"success","rounded":"","depressed":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(" Approve ")])],1)]),_c('G-notif',{ref:"notif"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }