<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

             <template v-slot:item.noRow="{ item }" >
                 {{tbData.map(function(x) {return x.id; }).indexOf(item.id)+1}}
             </template>

             <template v-slot:item.birthDate="{item}">
                <div class="p-2">
                    {{G_formatDate(item.birthDate,'d-M-Y')}}
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-menu transition="slide-x-transition" bottom right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#fafafa" class="btn-table"  fab depressed x-small v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list flat dense elevation="1">
                        <!-- <v-subheader>Action</v-subheader> -->
                        <v-list-item-group color="primary">
                            <v-list-item @click="detail(item.id)">
                                <v-list-item-icon class="mr-3">
                                    <v-icon small>mdi-file-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Detail</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                              <v-list-item @click="pembukaanTabungan(item.id,item.cifCode)">
                                <v-list-item-icon class="mr-3">
                                    <v-icon small>mdi-check</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Pilih CIF</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <G-dialogConfirm ref="confirm"/>
        <G-dialogModal ref="modal">
            <div slot="content">
                <div class="row mt-4">
                    <div class="col-md-6">
                        <h6 class="text-warning mb-4">Data Sebelumnya</h6>
                         <G-detail title="Nama Lengkap" value="Jono" />
                         <G-detail title="Tempat Lahir" value="Jakarta" />
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-success mb-4">Data Setelah</h6>
                         <G-detail title="Nama Lengkap" value="John Doe" />
                         <G-detail title="Tempat Lahir" value="Bandung" />
                    </div>
                </div>
            </div>
            <div slot="action">
                  <v-spacer></v-spacer>
                    <v-btn color="success" rounded text @click="dialog = false">
                        Reject
                    </v-btn>
                    <v-btn color="success" rounded depressed @click.native="agree">
                        Approve
                    </v-btn>
            </div>
        </G-dialogModal>

        <G-notif ref="notif" />    


    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                headers: [
                    { text: 'No', value: 'noRow',sortable: false,width: "1%" },
                    { text: 'No CIF', value: 'cifCode' },
                    { text: 'No Identitas', value: 'identityNo' },
                    { text: 'Nama Nasabah', value: 'fullName' },
                    { text: 'Tanggal Lahir', value: 'birthDate' },
                    { text: 'Alamat', value: 'address' },
                    // { text: 'Status', value: 'isActive' },
                    { text: 'Act', value: 'actions', sortable: false,width: "2%" },
                ],
            }
        },
        methods: {
            detail(id) {
                 this.$router.push({ name: 'cif_inquiry_detail', query: { id: id } });
            },
            pembukaanTabungan(id,code) {
                this.$router.push({ name: 'tabungan_form', query: { id: id, code: code } });
            },
            async setStatus(id,isActiveInit) {
                let judul = '';
                let subJudul = '';
                let isActive = false;

                if (isActiveInit == true) {
                    judul = "Non Aktifkan"
                    subJudul = "Yakin Akan Non Aktifkan Data Ini ?"
                    isActive = false
                }else{
                    judul = "Aktifkan"
                    subJudul = "Yakin Akan Aktifkan Data Ini ?"
                    isActive = true
                }
                
                if (await this.$refs.confirm.open(judul,subJudul) ) {
                    this.$refs.confirm.loading()

                    let formData = new FormData();
                    formData.append('isActive',isActive);
                    
                    this.G_PutAnyCIF('cif-personal/set-active/'+id, formData).then(res => {
                        console.log(res);
                        if (res.rc == 200) {
                            this.$refs.confirm.close()
                            this.getTable();
                            this.$refs.notif.success(`Berhasil ${judul}`,res.rm);
                        }else{
                            this.$refs.notif.warning(res.data.rm);
                        }
                    })

                    .finally(() => {
                        this.$refs.confirm.loadingEnd()
                    }); 
                }
            
            },
            setujui(id){
                 this.$refs.modal.open("Approval Perubahan CIF",500)
            },
            getTable(){
                this.$emit('getTable');
            },
            
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>