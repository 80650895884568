import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";
let urutantableSIMURP = 0;
let urutantableIPDMIP = 0;
let urutantableDOISP = 0;

export default {
  data() {
    return {
      search: "",
      loadingTb: false,
      isActive: [],
      options: null,
      summary: [],
      tableSBSN: [],
      tableProyek: [],
      tableLoan: [],
      tableSatker: [],
      tableSIMURP: [],
      tableDOISP: [],
      tableIPDMIP: [],
      labelIPDMIP: [
        {
          "1Z83YQPA": 0,
          "11DDKS1A": 0,
        },
      ],
      table8: [],
      table9: [],
      listStatker: [],
      listBalai: [],
      listGiat: [],
      listEvent: [],
      selectSatker: null,
      isTotalKodeAkun: false,
      isKodeAkunS1: false,
      isKodeAkunS2: false,
      isKodeAkunS3: false,
      istableLoan: false,
      istableSBSN: false,
      istableProyek: false,
      istableIPDMIP: false,
      istableSIMURP: false,
      istableDOISP: false,
      istable8: false,
      istable9: false,
      isShow: false,
      data_color: [
        "#00C5DC",
        "#F4516C",
        "#FFB822",
        "#8859E0",
        "#0C5484",
        "#66BB6A",
        "#00838F",
        "#e57373",
      ],

      currentPreviewKoreksiPorsi: {
        totalAdb: 0,
        totalAif: 0,
        listADBItem: [],
        listAifItem: [],
        registerAdb: null,
        registerAif: null,
      },

      currentEdit: {
        proyek: "",
        kodePaket: "",
        kdkppn: "",
        kdakun: "",
      },
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.chartOptions = {
      theme: {
        palette: "palette3",
      },
    };

    $(".selectpicker").selectpicker("render");

    this.G_GetRef("giat").then((data) => {
      this.listGiat = data;
    });
    // this.G_GetAny("sakti/event").then((res) => {
    //   let resData = res.data.content;

    //   let listData = [];
    //   let arrSub = {};
    //   let setDefault = null;

    //   resData.forEach((v) => {
    //     if (v.asDefault) {
    //       setDefault = v.saktiEventId;
    //     }

    //     arrSub = {};

    //     arrSub["text"] = v.saktiEventNm;
    //     arrSub["value"] = v.saktiEventId;

    //     listData.push(arrSub);
    //   });

    //   this.listEvent = listData;

    //   this.listEvent.unshift({
    //     text: "Semua Event",
    //     value: "all",
    //   });
    //   this.$refs.event.setValue(setDefault);

    // });

    this.G_GetRef("balai").then((data) => {
      this.listBalai = data;

      if (this.getUser.userGroup == "balai") {
        this.$refs.balai.setValue(this.getUser.kdbalai);
      } else if (this.getUser.userGroup == "satker") {
        this.$refs.balai.setValue(this.getUser.kdbalai);
      } else {
        this.listBalai.unshift({
          text: "Semua Balai",
          value: "all",
        });
      }
    });

    this.G_GetRef("satker").then((data) => {
      this.listStatker = data;
      if (this.getUser.userGroup == "satker") {
        this.$refs.satker.setValue(this.getUser.kdsatker);
      } else {
        this.listStatker.unshift({
          text: "Semua Satker",
          value: "all",
        });
      }
    });

    this.getSummary();

    // this.getSummary()
  },
  components: {
    myTable,
  },

  computed: {
    labelPorsi: function() {
      // return 'wadaw'
      return (
        "Data Loan IPDMIP terdiri dari 2 (dua) sumber dana, porsi ADB " +
        this.labelIPDMIP["1Z83YQPA"] +
        "% dan AIF " +
        this.labelIPDMIP["11DDKS1A"] +
        "%"
      );
    },
  },
  methods: {
    getSumRegister(type) {
      let s1 = 0;
      let s2 = 0;
      let s3 = 0;
      let total = 0;

      this.tableLoan.forEach((v) => {
        s1 += v.sumAkun51;
        s2 += v.sumAkun52;
        s3 += v.sumAkun53;
        total += v.total;
      });

      switch (type) {
        case "s1":
          return this.G_numFormat(s1 / 1000);
          break;
        case "s2":
          return this.G_numFormat(s2 / 1000);
          break;
        case "s3":
          return this.G_numFormat(s3 / 1000);
          break;
        case "total":
          return this.G_numFormat(total / 1000);
          break;
      }
    },
    getSumRegisterSBSN(type) {
      let s1 = 0;
      let s2 = 0;
      let s3 = 0;
      let total = 0;

      this.tableSBSN.forEach((v) => {
        s1 += v.sumAkun51;
        s2 += v.sumAkun52;
        s3 += v.sumAkun53;
        total += v.total;
      });

      switch (type) {
        case "s1":
          return this.G_numFormat(s1 / 1000);
          break;
        case "s2":
          return this.G_numFormat(s2 / 1000);
          break;
        case "s3":
          return this.G_numFormat(s3 / 1000);
          break;
        case "total":
          return this.G_numFormat(total / 1000);
          break;
      }
    },
    setSatker(id) {
      if (id != "all" || id == null) {
        this.G_GetRef("satker", "?balaiId=" + id).then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      } else {
        this.G_GetRef("satker").then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      }
    },
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }

    },

    getSummary() {
      this.istableIPDMIP = true;
      this.istableSIMURP = true;
      this.istableDOISP = true;
      this.istable8 = true;
      this.istable9 = true;

      let filter = [];

      if (this.$refs.balai.getValue()) {
        if (this.$refs.balai.getValue() != "all") {
          filter.push("kdbalai=" + this.$refs.balai.getValue());
        }
      }

      // if (this.$refs.event.getValue()) {
      //   if (this.$refs.event.getValue() != "all") {
      //     filter.push("saktiEventId=" + this.$refs.event.getValue());
      //   }
      // }

      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != "all") {
          filter.push("kdsatker=" + this.$refs.satker.getValue());
        }
      }

      filter.push("saktiEventId=" + this.$route.query.eventId);

      let giat = [];

      if (this.$refs.giat.getValue()) {
        giat.push(this.$refs.giat.getValue());
        let giatL = giat.join(",");
        filter.push("kdgiat=" + giatL);
      }

      filter = "?" + filter.join("&");

      this.G_GetAny("adk/resume/porsi/IPDMIP" + filter).then((res) => {
        if (res.data.content) {
          this.tableIPDMIP = res.data.content;
          this.labelIPDMIP = res.data.persenPorsi;
        } else {
          this.tableIPDMIP = [];
        }
        this.istableIPDMIP = false;
      });
      this.G_GetAny("adk/resume/porsi/SIMURP" + filter).then((res) => {
        if (res.data.content) {
          this.tableSIMURP = res.data.content;
        } else {
          this.tableSIMURP = [];
        }
        this.istableSIMURP = false;
      });
      this.G_GetAny("adk/resume/porsi/DOISP" + filter).then((res) => {
        if (res.data.content) {
          this.tableDOISP = res.data.content;
        } else {
          this.tableDOISP = [];
        }
        this.istableDOISP = false;
      });

      this.isShow = true;
    },
    cariData() {
      this.getSummary();
    },
    detailPaket(kodeSatker) {
      urutan = 0;

      this.G_GetAny(
        "kontraktual/per-paket/nested?kdsatker=" + kodeSatker + "&tahun=2021"
      ).then((res) => {
        this.tableDetailPaket = res.data.content[0].childs[0];

        this.$refs.modal.open(
          "DETAIL PAKET " + this.tableDetailPaket.nmsatker,
          1200
        );
      });
    },

    selectAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.selectAll();
      } else if (type == "giat") {
        this.$refs.giat.selectAll();
      }
    },
    removeAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.removeAll();
      } else if (type == "giat") {
        this.$refs.giat.removeAll();
      }
    },

    async koreksiPorsi(proyek, kodePaket) {

      let judul = "Koreksi Porsi";
      let subJudul = "Apakah anda akan mengkoreksi porsi data ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();

        let formData = new FormData();

        formData.append("saktiEventId", this.$route.query.eventId);
        formData.append("proyek", proyek);
        formData.append("kodePaket", kodePaket);

        this.G_PostAny("/porsiLoan/koreksi/", formData)
          .then((res) => {
            if (!res.status) {
              this.$refs.confirm.close();
              this.getSummary();
              this.$refs.notif.success(`Berhasil mengkoreksi porsi paket`);
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },

    async directKoreksiPorsi() {

      this.$refs.confirm.loading();

      let formData = new FormData();

      formData.append("saktiEventId", this.$route.query.eventId);
      formData.append("proyek", this.currentEdit.proyek);
      formData.append("kodePaket", this.currentEdit.kodePaket);
      formData.append("kdakun", this.currentEdit.kdakun);

      this.G_PostAny("/porsiLoan/koreksi/", formData)
        .then((res) => {
          if (!res.status) {
            this.$refs.confirm.close();
            this.getSummary();
            this.$refs.notif.success(`Berhasil mengkoreksi porsi paket`);

            this.$refs.modelPreviewKoreksiPorsi.close();
          } else {
            this.$refs.notif.warning("Terjadi Kesalahan");
          }
        })
        .finally(() => {
          this.$refs.confirm.loadingEnd();
        });
    },

    async previewKoreksiPorsi(proyek, kodePaket, kdakun) {
      this.currentEdit.proyek = proyek;
      this.currentEdit.kodePaket = kodePaket;
      this.currentEdit.kdakun = kdakun;

      // let judul = "Preview Koreksi Porsi";
      // let subJudul = "Apakah anda akan mempreview mengkoreksi porsi data ini?";

      // if (await this.$refs.confirm.open(judul, subJudul)) {

      this.$refs.confirm.loading();

      let formData = new FormData();

      formData.append("saktiEventId", this.$route.query.eventId);
      formData.append("proyek", proyek);
      formData.append("kodePaket", kodePaket);
      formData.append("kdakun", kdakun);

      this.G_PostAny("/porsiLoan/koreksi/preview/", formData)
        .then((res) => {
          if (!res.status) {
            this.$refs.confirm.close();

            this.currentPreviewKoreksiPorsi = res;
            this.$refs.modelPreviewKoreksiPorsi.open(
              "Preview Koreksi Porsi",
              1200
            );
            //preview koreksi
          } else {
            this.$refs.notif.warning("Terjadi Kesalahan");
          }
        })
        .finally(() => {
          this.$refs.confirm.loadingEnd();
        });
      // }
    },
  },
};
